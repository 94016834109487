@import './../../../scss/variables';
@import './../../../scss/mixins';

.wrapper-modal-delete {
  margin-top: $spacing-4;

  &__content {
    &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: $spacing-4;
      margin-top: $spacing-8;
    }
  }
}
