@-webkit-keyframes spin-around {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(359deg);
  }
}
@keyframes spin-around {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(359deg);
  }
}
