@import '../../../scss/variables';
@import '../../../scss/mixins';

.breadcrumbs {
  &__item {
    &.dark {
      color: $dark-theme-text;
    }

    @include xs-xs-semibold;
    align-items: center;
    color: $secondary;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
    display: flex;
    gap: $spacing-1;

    &:hover {
      color: $primary-60;
      text-decoration: none;
    }

    &--active {
      @include xs-xs-semibold;
      align-items: center;
      color: $grey-70;
    }
  }
}

.page {
  margin-bottom: $spacing-4;

  &--title {
    margin-bottom: 10px;

    h2 {
      @include md-md-bold;
      color: $secondary;
    }
  }
}
