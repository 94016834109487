@import './../../scss/variables';
@import './../../scss/mixins';

.expectation-form-detail {
  &__title-expectation {
    @include sm-sm-medium;
    color: $tertiary;

    strong {
      @include sm-sm-bold;
      color: $secondary;
    }
  }

  &__title {
    @include xs-xs-medium;
    color: $tertiary;
  }

  &__description {
    @include sm-sm-semibold;
    color: $secondary;
  }
}

.wrapper-grand-total {
  background: $background;
  border-radius: $spacing-4;
}

.button {
  &__download {
    > a {
      color: $true-white;
      text-decoration: none;
    }
  }
}
