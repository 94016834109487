// Styles
@import '~bootstrap/dist/css/bootstrap.css';

// CoreUI Icons Set
@import '~@coreui/icons/css/all.min.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';

@import '../scss/style.scss';
@import './variables.scss';
@import './mixins.scss';
// Import Open Sans from Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

// Set Open Sans as the default font family
body {
  font-family: 'Open Sans', sans-serif;
}

