@import '../../../scss/vendors/bcolors.scss';
@import '../../../scss/mixins.scss';

.container-personal-wisdom-section {
  &__carousel {
    border-radius: $spacing-4;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  &__item {
    background-color: $true-white;
    border-radius: $spacing-4;
    padding: $spacing-12 $spacing-10;
    height: 300px;

    &--homepage {
      padding: $spacing-12 $spacing-10;
    }

    &__description {
      // Add the following properties
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; // Set the number of lines to display
      -webkit-box-orient: vertical;
      max-height: 80px;
      text-overflow: ellipsis;
    }

    &__image-cover {
      position: relative;
      height: 200px;
      width: 200px;
      border-radius: 10px;
      overflow: hidden;
      // background-color: $true-white;
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
