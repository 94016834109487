@import '../../../scss/variables';
@import '../../../scss/mixins';

.wrapper-dialog-logout {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__content {
    display: flex;
    gap: 32px;
    flex-direction: column;

    p {
      @include sm-sm-medium;
    }

    &__actions {
      display: flex;
      gap: 16px;
      justify-content: space-between;

      div:last-child {
        align-items: center;
        justify-content: center;
        display: flex;
        gap: $spacing-2;
      }

      div {
        flex: 1;
      }
    }
  }

  h2 {
    @include lg-lg-bold;
    color: $secondary;
  }
}
