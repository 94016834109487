@import '../../../scss/variables';
@import '../../../scss/mixins';

.beelajar-button {
  // Default styles for the button
  padding: 6px 12px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: $spacing-2;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $spacing-2;

  &.disabled {
    // Styles for the solid variant
    background-color: $grey-10 !important;
    color: $grey-50 !important;
    cursor: not-allowed;
  }

  &.error {
    // Styles for the solid variant
    background-color: $red-60;
    color: $true-white;

    &:hover {
      // Hover effect for the solid variant
      background-color: $red-70;
    }
  }

  &.solid {
    // Styles for the solid variant
    background-color: $orange-60;
    color: $true-white;
    justify-content: center;

    &:hover {
      // Hover effect for the solid variant
      background-color: $orange-70;
    }
  }

  &.outline {
    // Styles for the solid variant
    background-color: $true-white;
    border: 1px solid $primary-60;
    color: $primary-60;

    &:hover {
      // Hover effect for the solid variant
      background-color: $grey-10;
      border: 1px solid $primary-60;
    }
  }

  &.soft {
    // Styles for the solid variant
    background-color: $orange-10;
    border: 1px solid $orange-10;
    color: $orange-60;

    &:hover {
      // Hover effect for the solid variant
      background-color: $orange-20;
      border: 1px solid $orange-20;
      color: $true-white;
    }
  }

  &.transparent {
    // Styles for the solid variant
    background-color: transparent;
    border: none;
    color: $primary-60;

    &:hover {
      // Hover effect for the solid variant
      background-color: $grey-10;
    }
  }

  &.error-beelajar {
    // Styles for the solid variant
    background-color: $grey-30;
    border: 1px solid $grey-30;
    color: $grey-80;

    &:hover {
      // Hover effect for the solid variant
      background-color: $grey-40;
    }
  }

  &.sm {
    // Styles for the solid variant
    min-height: 40px;
    padding: 8px 16px;
    @include sm-sm-semibold;
  }

  &.md {
    // Styles for the solid variant
    padding: 10px 20px;
    @include md-md-semibold;
  }

  &.lg {
    // Styles for the solid variant
    padding: 12px 24px;
    @include lg-lg-semibold;
  }

  &.full {
    // Styles for the solid variant
    width: 100%;
    flex: 1;
  }

  &.link {
    // Styles for the solid variant
    background-color: 'red';
    border: none;
    color: $primary-60;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    width: fit-content;

    &:hover {
      // Hover effect for the solid variant
      background-color: transparent;
      border: none;
      color: $primary-50;
      text-decoration: underline;
    }

    &:focus {
      // Hover effect for the solid variant
      background-color: transparent;
      border: none;
      color: $primary-80;
    }
  }

  &:focus {
    // Hover effect for the solid variant
    outline: none;
  }

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
