@import '../../../../../scss/variables';
@import '../../../../../scss/mixins';

.wrapper-dropdown-profile {
  position: relative;

  &__dropdown {
    background-color: transparent;
    padding: $spacing-1 $spacing-2;
    border-radius: $spacing-2;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &.dark {
      &:hover {
        background-color: $grey-80;
      }
    }

    &:hover {
      background-color: $grey-10;
    }

    &__content {
      align-items: center;
      display: flex;
      gap: $spacing-2;

      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }

      p {
        @include sm-sm-bold;
      }
    }

    &__container {
      position: absolute;
      top: 100%;
      border-radius: $spacing-1;
      background-color: $true-white;
      width: 100%;
      padding: $spacing-2 0;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

      &.dark {
        background-color: $dark-theme-background;

        &div > p {
          color: $dark-theme-text !important;
        }
      }
    }
  }
}

.dropdown-navigation-item {
  display: flex;
  align-items: center;
  gap: $spacing-2;
  width: 100%;
  cursor: pointer;
  padding: $spacing-1 $spacing-3;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  color: $secondary;
  text-decoration: none;

  &:hover {
    background-color: $grey-10;
    color: $secondary;
    text-decoration: none;
  }

  &.logout {
    color: $red-60;
  }

  p {
    @include sm-sm-bold;
  }
}

.wrapper-category-menu {
  position: relative;

  button {
    &.dark {
      color: $background-testimony;

      &:hover {
        color: $background-testimony;
        background-color: $tertiary;
        opacity: 0.6;
        transition: all 0.3s ease-in-out;
      }
    }

    &:hover {
      color: $secondary;
      transition: all 0.3s ease-in-out;
    }
  }

  &__container {
    position: absolute;
    top: 100%;
    border-radius: $spacing-1;
    background-color: $true-white;
    min-width: 280px;
    padding: $spacing-2 0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    .menu-category-item {
      position: relative;
      width: 100%;
      padding: $spacing-1 $spacing-3;
      transition: all 0.3s ease-in-out;
      background-color: transparent;
      color: $secondary;
      cursor: pointer;

      &:hover {
        background-color: $grey-10;
        color: $primary-60;
      }

      &__category {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
          @include sm-sm-bold;
        }
      }

      &__subcategory {
        position: absolute;
        top: 0;
        left: 100%;
        border-radius: $spacing-1;
        background-color: $true-white;
        min-width: 280px;
        padding: $spacing-2 0;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

        ul {
          padding: 0;
          margin: 0;
        }

        li {
          @include sm-sm-bold;
          position: relative;
          width: 100%;
          padding: $spacing-1 $spacing-3;
          transition: all 0.3s ease-in-out;
          background-color: transparent;
          color: $secondary;
          list-style: none;
          margin: 0;
          cursor: pointer;

          &:hover {
            background-color: $grey-10;
            color: $primary-60;
          }
        }

        &__sub-subcategory {
          position: absolute;
          top: 0;
          left: 100%;
          border-radius: $spacing-1;
          background-color: $true-white;
          min-width: 280px;
          padding: $spacing-2 0;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

          ul {
            padding: 0;
            margin: 0;
          }

          li {
            @include sm-sm-bold;
            position: relative;
            width: 100%;
            padding: $spacing-1 $spacing-3;
            transition: all 0.3s ease-in-out;
            background-color: transparent;
            color: $secondary;
            list-style: none;
            margin: 0;
            cursor: pointer;

            &:hover {
              background-color: $grey-10;
              color: $primary-60;
            }
          }
        }
      }
    }
  }
}

.wrapper-menu-mobile {
  background-color: $true-white;
  top: 65px;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 9999;
  overflow: auto;

  &__item {
    padding: 8px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    gap: $spacing-3;
    text-decoration: none !important;
    transition: all 0.3s ease-in-out;

    p {
      @include xs-xs-bold;
      color: $grey-70;
      flex: 1;
    }

    img {
      border-radius: 50%;
    }

    &:hover {
      background-color: $grey-10;
    }
  }
}
