.button--azure--ad {
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
  padding: 10px 50px;
  border-radius: 15px;
  color: rgba(0, 0, 0, 0.5);
}

.button--azure--ad:hover {
  border: 1px solid rgba(0, 0, 0, 0.3);
  opacity: 0.6;
}

.button--azure--ad img{
  width: 25px; 
}

.login-wrapper .title{
  font-weight: 600;
}

.btn-success.login{
	background: #7B2183;
	border: 2px solid #7B2183;
	color: #fff !important;
	font-weight: 600;
	padding-right: 25px !important;
	padding-left: 25px !important;
	border-radius: 15px;
}
.btn-success.login:hover{
	background: #BB9CBD;
	border: 2px solid #BB9CBD;
}