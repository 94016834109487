@import './../../../scss/variables';
@import './../../../scss/mixins';

.toolbox-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing-4;
  flex-direction: row;
  transition: all 0.3s ease-in-out;

  &--left {
    display: flex;
    align-items: center;
    flex: 1;
    gap: $spacing-4;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    & > div {
      @media (max-width: 768px) {
        flex: 1;
        width: 100%;
      }

      & > div {
        @media (max-width: 768px) {
          flex: 1;
          width: 100%;
        }
      }
    }
  }

  &__search {
    max-width: 200px;

    @media (max-width: 768px) {
      width: 100%;
      max-width: none;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: $spacing-4;
    flex-wrap: wrap;
    width: 100%;

    button {
      width: 100%;
    }
  }
}
