/**
 * Set base font size, because I using rem.
 */
 html {
  //font-size: 10px;
}

/**
* Reset body content.
* @font-family using Google Font.
*/
body {
  margin: 0;
  padding: 0;
  // font-family: 'Open Sans', sans-serif;
  font-family: Helvetica,Arial,sans-serif;
  font-size: 1rem;
  font-weight: normal;
  color: $text-color;
  line-height: 1.6;
  background-color: #fff;
}

/**
* Input placeholder.
*/
::-webkit-input-placeholder {
  color: #ababab;
}
::-moz-placeholder {
  color: #ababab;
}
:-ms-input-placeholder {
  color: #ababab;
}
:-moz-placeholder {
  color: #ababab;
}

/**
* Overriding container width.
*/
.container {
  padding: 0;
  width: 100%;
  max-width: $container-width;
  @media (max-width: $container-width + 32) {
      padding: 0 16px;
  }
}
.container--fluid {
  padding: 0 16px;
  @media (min-width: $container-width + 32) {
      padding: 0 24px;
  }
}

/**
* Module container.
*/
.module--container {
  display: block;
  position: relative;
  padding: 32px 0;
  @media (min-width: $screen-small) {
      padding: 48px 0;
  }
  @media (min-width: $screen-medium) {
      padding: 56px 0;
  }
  @media (min-width: $screen-large) {
      padding: 64px 0;
  }
  &.-has-divider {
      @include border('top');
  }
}

/**
* Centering element.
*/
.elm--center {
  margin-left: auto !important;
  margin-right: auto !important;
}

/**
* Material icons.
*/
.material-icons {
  margin-top: -2px;
  vertical-align: middle;
  line-height: 0;
  text-align: center;
  &.-display-block {
      display: block;
      margin-top: 0;
      line-height: 1;
  }
}

/**
* Row with gap.
*/
.row.-has-gap {
  margin: 0 -8px;
  > * {
      padding: 0 8px 16px;
  }
}

.-full-height {
  height: 100%;
}
.-full-width {
  width: 100%;
}
.-no-selection {
  @include no-selection();
}
.-fixed-layout {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
}
