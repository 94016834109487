.loading-image {
  opacity: 0;
  animation: fadeIn 2s infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0.2;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
    transform: translateY(-10px);
  }
  100% {
    opacity: 0.2;
    transform: translateY(0);
  }
}
