@import './../../../scss/variables';
@import './../../../scss/mixins';

.loader-datatables {
  thead,
  tbody {
    tr {
      border-bottom: 1px solid $border-color;

      th,
      td {
        gap: $spacing-2;
        padding: $spacing-2;
      }
    }
  }
}

.sortable-column {
  display: flex;
  align-items: center;
  gap: $spacing-1;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $primary-60;
  }
}

.table-empty-message {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  &__text {
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: $spacing-2;

    h5 {
      @include sm-sm-bold;
      color: $secondary;
    }

    p {
      @include xs-xs-semibold;
      color: $grey-60;
    }
  }
}

.wrapper-datatables {
  display: grid;
}
