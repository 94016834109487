@import '../../../scss/variables';
@import '../../../scss/mixins';

.wrapper-right {
  &__container {
    &__login {
      display: inline;

      @media (max-width: 768px) {
        display: none !important;
      }
    }
  }
}
