/// import global scss here instead of import this local scss to global scss folder
@import "../../scss/variables";
@import "../../scss/mixins";

/// create class name here
.c-page-header-wrapper {
  min-height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  // padding-left: $gap-medium;
  // padding-right: $gap-medium;
  &:not(.use-margin) {
    //padding-left: $gap-medium;
    //padding-right: $gap-medium;
    padding-top: $gap-small;
    padding-bottom: 5px;
  }

  &.use-margin {
    margin: $gap-medium;
  }
}

@media (max-width: 575.98px) {
  .c-page-header-wrapper:not(.use-margin) {
    margin-left: -17px;
    margin-right: -17px;
  } 
}