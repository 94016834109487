a {
  color: $primary-color;
  &:hover {
      color: $primary-color;
  }
  &:focus {
      outline: 0;
  }
}

button {
  padding: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
  box-shadow: none;
}

img {
  max-width: 100%;
}

hr {
  display: block;
  margin: 32px 0;
  padding: 0;
  width: 100%;
  height: 1px;
  background-color: $border-color;
  border: 0;
  outline: 0;
  @media (min-width: $screen-small) {
      margin: 40px 0;
  }
  @media (min-width: $screen-medium) {
      margin: 48px 0;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 12px;
}
