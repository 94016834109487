.personal-wisdom {
  padding: 2rem 0;
}

.personal-wisdom-container {
  -moz-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
}

.personal-wisdom .carousel-item > div {
  height: 25rem;
  // padding: 50px;
}

.personal-wisdom .short-description {
  text-align: center;
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 10px;
}
.personal-wisdom .name-and-position {
  text-align: center;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 10px;
}

.personal-wisdom .short-description {
  white-space: pre-wrap;
  /* CSS3 */
  white-space: -moz-pre-wrap;
  /* Firefox */
  white-space: -pre-wrap;
  /* Opera <7 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* IE */
  margin-bottom: 25px;
}

.personal-wisdom .name-and-position {
  font-weight: bold;
  line-height: 25px;
}

.personal-wisdom .detail-button {
  text-align: center;
  margin-top: 50px;
}

.personal-wisdom .detail-button button {
  background-color: #5f1965;
  color: #fff;
  height: 50px;
  padding: 10px 40px;
  border-radius: 15px;
}

.personal-wisdom img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.carousel-control-next-icon {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-position-x: 50%;
  background-position-y: center;
  background-size: 80% 80%;
}

@media (max-width: 767.98px) {
  .personal-wisdom img {
    max-width: 180px;
  }

  .personal-wisdom-container {
    -moz-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
  }

  .personal-wisdom .short-description,
  .personal-wisdom .name-and-position {
    text-align: center;
    font-size: 12px;
    line-height: 15px;
    margin-top: 10px;
  }

  .personal-wisdom .carousel-item > div {
    height: 500px;
    padding: 40px;
  }

  .personal-wisdom .detail-button {
    margin-top: 20px;
  }

  .personal-wisdom .detail-button button {
    background-color: #5f1965;
    color: #fff;
    padding: 5px 30px;
    border-radius: 15px;
  }
}

@import '../../../scss/vendors/bcolors.scss';
@import '../../../scss/mixins.scss';

.container-testimony-item {
  border-radius: 12px;
  background-color: $background-testimony;
  box-shadow: 0px 10px 20px 0px rgba(42, 98, 234, 0.1);
  // min-width: 250px;
  // max-width: 350px;
  height: 100%;
  border: 1px solid $border;
  padding: $spacing-4;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  opacity: 0.8;
  position: relative;
  transition: all 0.3s ease-in-out;

  @media (max-width: 768px) {
    width: 100%;
    max-width: none;
  }

  &:hover {
    transform: translateY(-6px);
    // background-color: #fef1ff;
    border: 1px solid $primary-60;
    opacity: 1;
  }

  &__image-cover {
    position: relative;
    height: 45px;
    width: 45px;
    border-radius: 45px;
    overflow: hidden;
    background-color: $true-white;
    border: 1px solid $grey-10;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__wrapper {
    border-radius: 12px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    background-color: rgb(248 171 255 / 5%);
    transition: all 0.3s ease-in-out;

    &.show {
      display: flex;
    }
  }
}
