@import './../../../scss/variables';
@import './../../../scss/mixins';

.modal-wrapper {
  &__title {
    h2 {
      @include lg-lg-bold;
      color: $secondary;
    }
    
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__close-button {
    width: 30px;
    height: 30px;
    border-radius: 10px;
    background-color: $grey-10;
    transition: all 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: $grey-20;
    }
  }
}
