@import '../../../../scss/vendors/bcolors.scss';
@import '../../../../scss/mixins.scss';

.indicator {
  width: 10px !important;
  height: 10px !important;
  transition: width 500ms ease;
  background-color: $primary-10;

  &[data-active] {
    background-color: $primary-60;
    width: 40px !important;
  }
}

.indicators {
  gap: 5px;
}

.control {
  background-color: $orange-60;
  color: $true-white;
  border: $orange-60;
  width: 35px;
  height: 35px;
}

.controls {
  top: 35%;
}

.root {
  &:hover {
    .controls {
      opacity: 1;
    }
  }
}
.container {
  padding-bottom: 32px;
}
.viewport {
  border-radius: 10px;
}
