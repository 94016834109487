@import './../../../scss/variables';
@import './../../../scss/mixins';

.badge-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 500;
  width: 25px;
  height: 25px;
  background-color: $red-60;
  color: $true-white;
}
