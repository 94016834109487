@import "../../scss/variables";
@import "../../scss/mixins";

.select-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }


  .reset-button-div{
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin-right: 5px;
  }
  
  .reset-button{
    width: 125px;
  }
  

  .new-button-div{
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin-right: 20px;
  }


  .submit-button-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .modal-detail-buttons-div{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }


  .table-responsive{
    display: flex;
    overflow-x: auto;
    flex-direction: column;
   max-width: 100%;
  }

  