@import '../../../scss/vendors/bcolors.scss';
@import '../../../scss/mixins.scss';

.container_event_item {
  background-color: $true-white;
  height: 100%;
  padding: 10px;
  // width: 250px;
  box-shadow: 0px 10px 20px 0px rgba(42, 98, 234, 0.1);
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: $spacing-4;
  justify-content: space-between;
  position: relative;
  transition: width 0.2s ease-in-out;

  @media (max-width: 768px) {
    width: 100%;
  }

  &__image_header {
    height: 110px;
    margin-top: -10px;
    margin-left: -10px;
    margin-right: -10px;

    &__image_content {
      width: 100%;
      height: 110px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-color: $true-white;
      margin-bottom: 10px;
    }
  }
}

#scrollarea-invalid {
  overflow-y: scroll;
  height: 200px;
}
#scrollarea-content {
  min-height: 101%;
}
