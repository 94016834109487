@import '../../../scss/vendors/bcolors.scss';
@import '../../../scss/mixins.scss';

.container-testimony-item {
  border-radius: 12px;
  background-color: $background-testimony;
  box-shadow: 0px 10px 20px 0px rgba(42, 98, 234, 0.1);
  // min-width: 250px;
  // max-width: 350px;
  height: 100%;
  border: 1px solid $border;
  padding: $spacing-4;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  opacity: 0.8;
  position: relative;
  transition: all 0.3s ease-in-out;

  &.carousel {
    margin: 16px 8px;
    height: 270px;

    @media (max-width: 768px) {
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: none;
  }

  @media (max-width: 1024px) {
    width: fit-content;
  }

  &:hover {
    transform: translateY(-6px);
    // background-color: #fef1ff;
    border: 1px solid $primary-60;
    opacity: 1;
  }

  &__image-cover {
    position: relative;
    height: 45px;
    width: 45px;
    border-radius: 45px;
    overflow: hidden;
    background-color: $true-white;
    border: 1px solid $grey-10;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__wrapper {
    border-radius: 12px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    background-color: rgb(248 171 255 / 5%);
    transition: all 0.3s ease-in-out;

    &.show {
      display: flex;
    }
  }
}
