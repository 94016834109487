@import '../../../scss/variables';
@import '../../../scss/mixins';

.preview_container {
  min-height: 200px;
  border: 1px dashed $border;
  border-radius: 10px;
  background-color: $background;
  padding: $spacing-4;

  &__image_preview {
    width: 200px;
    max-width: unset;
  }
}
