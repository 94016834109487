@import '../../../scss/variables';

.menus-container {
  padding: $spacing-4;
  display: flex;
  flex-direction: column;
  gap: $spacing-1;

  @media (max-width: 768px) {
    padding: 0px;
    gap: 0px;
  }
}

.menu-item-container {
  padding: 8px 12px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none !important;
  transition: all 0.3s ease-in-out;

  &.active {
    background-color: $light-primary;

    p {
      @include sm-sm-bold;
      color: $primary-60;

      @media (max-width: 768px) {
        @include xs-xs-bold;
      }
    }
  }

  &.activeChildren {
    p {
      @include sm-sm-bold;
      color: $primary-60;

      @media (max-width: 768px) {
        @include xs-xs-bold;
      }
    }
  }

  &:hover {
    background-color: $grey-10;
  }

  &__menu-item {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;

    &.active {
      color: $primary-60;
    }

    p {
      @include sm-sm-bold;
      color: $grey-70;

      @media (max-width: 768px) {
        @include xs-xs-bold;
      }
    }
  }

  @media (max-width: 768px) {
    border-radius: 0px;
  }
}

.menu-container-children {
  display: flex;
  flex-direction: column;
  gap: $spacing-1;
  padding: 0px;

  a {
    @include sm-sm-bold;
    color: $grey-70;
    padding: 8px 12px;
    border-radius: 6px;
    text-decoration: none;
    cursor: pointer;
    text-wrap: wrap;
    word-break: keep-all;
    word-break: normal;
    transition: all 0.3s ease-in-out;
    margin-left: calc($spacing-8 + $spacing-1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      white-space: normal;
      overflow: visible;
      text-overflow: unset;
      background-color: $grey-10;
    }

    &.active {

      span {
        @include sm-sm-bold;
        color: $primary-60;

        @media (max-width: 768px) {
          @include xs-xs-bold;
        }
      }
    }

    span {
      @include sm-sm-bold;

      @media (max-width: 768px) {
        @include xs-xs-bold;
      }
    }

    @media (max-width: 768px) {
      border-left: 1px solid $border;
      border-radius: 0px;
    }
  }

  @media (max-width: 768px) {
    gap: 0px;
  }
}
