@import '../variables';

.mantine-Textarea-label,
.mantine-TextInput-label,
.mantine-DatePickerInput-label,
.mantine-RadioGroup-label,
.mantine-Radio-label,
.mantine-PasswordInput-label,
.mantine-MonthPickerInput-label,
.mantine-Select-label,
.mantine-TagsInput-label,
.mantine-ColorInput-label,
.mantine-MultiSelect-label {
  @include xs-xs-semibold;
  color: $secondary;
}

.mantine-Select-input,
.mantine-Textarea-input,
.mantine-DatePickerInput-input,
.mantine-PasswordInput-input,
.mantine-MonthPickerInput-input,
.mantine-TextInput-input,
.mantine-MultiSelect-input,
.mantine-FileInput-input,
.mantine-TagsInput-input,
.mantine-ColorInput-input,
.mantine-Dropzone-root {
  border-radius: 10px;
}

.mantine-Select-input,
.mantine-Textarea-input,
.mantine-DatePickerInput-input,
.mantine-PasswordInput-input,
.mantine-MonthPickerInput-input,
.mantine-TextInput-input,
.mantine-TagsInput-input,
.mantine-ColorInput-input,
.mantine-MultiSelect-input {
  &:focus {
    border-color: $primary-60;
  }
  &:focus-within {
    border-color: $primary-60;
  }
  &:focus-visible {
    border-color: $primary-60;
    outline: 2px solid $primary-60;
  }
}

.mantine-Input-wrapper {
  &.mantine-MonthPickerInput-wrapper {
    min-width: 200px;
  }
}

.mantine-RadioGroup-description {
  font-size: 10px;
  font-weight: 700;
}

.mantine-Radio-radio {
  &:checked {
    background-color: $primary-60;
    border-color: $primary-60;
  }
}

.mantine-LoadingOverlay-loader {
  &::after {
    border-color: $primary-60 $primary-60 $primary-60 transparent;
  }
}

.mantine-Checkbox-input {
  &:checked {
    background-color: $primary-60;
  }
}

button {
  &.mantine-focus-auto {
    &.mantine-active {
      &.mantine-Pagination-control {
        &[data-active='true'] {
          background-color: $primary-60;
          border-color: $primary-60;
        }
      }
    }
  }
}

a.mantine-Menu-item {
  text-decoration: none;

  &:hover {
    color: $primary-60;
  }
}

.mantine-Tabs-tab {
  &[data-active='true'] {
    color: $primary-60;
    border-color: $primary-60;
  }
}

.mantine-Popover-dropdown {
  border-color: $border;
  z-index: 999993 !important;
}

.mantine-Popover-arrow {
  border-color: $border;
  box-shadow: 0px 10px 20px 0px rgba(42, 98, 234, 0.1);
}

.mantine-SegmentedControl-root {
  background-color: $true-white;
  border: 1px solid $border;
}

.mantine-SegmentedControl-control > .mantine-SegmentedControl-label {
  &[data-active='true'] {
    background-color: $primary-60;
    color: $true-white;
  }
  @include xs-xs-bold;
  color: $primary-30;
  border: none;
  margin: 0px !important;
}
