@import '../../../scss/vendors/bcolors.scss';
@import '../../../scss/mixins.scss';

.container_content_item {
  background-color: $true-white;
  height: 230px;
  // height: 100%;
  padding: 10px;
  // min-width: 250px;
  box-shadow: 0px 10px 20px 0px rgba(42, 98, 234, 0.1);
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  transition: width 0.2s ease-in-out;

  @media (max-width: 768px) {
    width: 100%;
    height: 250px;
  }

  &.carousel {
    margin: 16px 8px;

    @media (max-width: 768px) {
      margin: 0;
    }
  }

  &__content {
    flex: 1;

    &-wrapper-cover {
      position: relative;
      height: 110px;
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      background-color: $true-white;
      border: 1px solid $grey-10;
    }

    &-cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &-title {
      // Add the following properties
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; // Set the number of lines to display
      -webkit-box-orient: vertical;
      max-height: 36px;
      text-overflow: ellipsis;
      max-width: 230px;
    }
  }

  &__detail-content {
    background-color: $true-white;
    position: absolute;
    height: 225px;
    overflow: auto;
    width: 250px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
    padding: $spacing-4;
    border-radius: 8px;
    box-shadow: 0px 10px 20px 0px rgba(42, 98, 234, 0.2);

    // Add the following properties
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      border-width: 10px;
      border-style: solid;
      z-index: 99;
    }

    &.left {
      left: 100%;

      // Add the following properties
      &::before {
        right: 100%;
        border-color: transparent $true-white transparent transparent;
      }
    }

    &.right {
      right: 100%;

      // Add the following properties
      &::before {
        left: 100%;
        border-color: transparent transparent transparent $true-white;
      }
    }
  }
}
