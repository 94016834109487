.block--display {
  display: block;
  margin: 0;
  font-weight: normal;
}
h1.block--display {
  font-size: 6rem;
  line-height: 1.1;
}
h2.block--display {
  font-size: 5.4rem;
  line-height: 1.15;
}
h3.block--display {
  font-size: 4.8rem;
  line-height: 1.2;
}
h4.block--display {
  font-size: 4rem;
  line-height: 1.25;
}
