.block--title {
  display: block;
  margin: 0;
  font-weight: bold;
}
h1.block--title {
  font-size: 4rem;
  line-height: 1.1;
}
h2.block--title {
  font-size: 3.4rem;
  line-height: 1.15;
}
h3.block--title {
  font-size: 3rem;
  line-height: 1.2;
}
h4.block--title {
  font-size: 2.6rem;
  line-height: 1.25;
}
h5.block--title {
  font-size: 2rem;
  line-height: 1.3;
}
h6.block--title {
  font-size: 1.6rem;
  line-height: 1.4;
}
