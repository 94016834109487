@import '../../../scss/variables';
@import '../../../scss/mixins';

body {
  word-wrap: break-word;
}

@media (min-width: 992px) {
  .sidebar-fixed .sidebar {
    width: 270px;
    border-right: 1px solid #c8ced3;
  }

  html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed {
    .main,
    .app-footer {
      margin-left: 270px;
    }
  }
}

@media (min-width: 768px) {
  html:not([dir='rtl']) .sidebar-md-show.sidebar-fixed .main,
  html:not([dir='rtl']) .sidebar-md-show.sidebar-fixed .app-footer,
  html:not([dir='rtl']) .sidebar-show.sidebar-fixed .main,
  html:not([dir='rtl']) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 270px;
  }
}

.img-preview-wrapper {
  position: relative;
  width: 300px;
}

.img-preview-wrapper .btn-wrap {
  width: 100px;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.img-preview-wrapper:hover .btn-wrap {
  opacity: 1;
}

@media (min-width: 576px) {
  html:not([dir='rtl']) .sidebar-sm-show.sidebar-fixed .main,
  html:not([dir='rtl']) .sidebar-sm-show.sidebar-fixed .app-footer,
  html:not([dir='rtl']) .sidebar-show.sidebar-fixed .main,
  html:not([dir='rtl']) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 270px;
  }
}

.custom-file-label {
  z-index: 0 !important;
}

.react-time-picker__wrapper {
  border-radius: 5px;
  padding-left: 7px;
  border-color: #cccccc !important;
}

.custom-file-input {
  z-index: 1 !important;
}

.btn-warning.btn-lg {
  background: #f08700;
  color: #fff;
  border-radius: 10px;
  padding: 0.38rem 1rem !important;
}

.btn-success.btn-lg {
  color: #fff;
  border-radius: 10px;
  padding: 0.38rem 1rem !important;
}

.btn-master.btn-lg {
  color: #4bc0c0;
  border-radius: 0px 0px 11px 11px;
  border-top: unset;
  border-color: rgba(0, 0, 0, 0.125);
}

.btn-master.btn-lg.active {
  color: #fff !important;
  background: #4bc0c0 !important;
  border-color: unset;
}

.form-control,
.input-group {
  height: 40px;
  border-color: hsl(0, 0%, 80%) !important;
}

// textarea.form-control{
//   height: auto !important;
// }
.form-check-input {
  position: relative !important;
  margin-left: unset !important;
  margin-right: 10px;
}

.table {
  border-bottom: 1px solid #c8ced3;
  border-left: 1px solid #c8ced3;
  border-right: 1px solid #c8ced3;
}

.table thead th {
  border-bottom: unset;
}

.table .action-col {
  width: 170px;
}

.breadcrumb {
  background: transparent;
  border-bottom: unset;
  padding-left: 0px;
  margin: 0px;
  font-weight: 500;
  color: #000 !important;
  font-size: 20px;
}

html:not([dir='rtl']) .sidebar {
  margin-left: -270px;
}

.app-footer {
  // padding: 0 25px;
  background: #fff;
  font-weight: 500;
}

.app-toogle {
  font-size: 35px;
  margin: 15px;
}

.app-body.default-layout {
  margin-top: 90px;
}

// .app-header {
//   height: 90px;
// }

// .app-header .navbar-brand {
//   width: 200px;
// }

.navbar-brand-full {
  margin-top: 2px;
}

.sidebar {
  width: 270px;

  .sidebar-nav,
  .nav {
    width: 269px;
    padding-left: 2px;
    margin-bottom: 20px;
  }

  .sidebar-minimizer::before,
  .nav-dropdown-toggle::before {
    color: #7b2183;
    width: 12px;
    height: 12px;
  }

  .nav-link {
    display: flex;
    padding-left: 1.3rem !important;
    padding-right: 1.7rem !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    line-height: 22px;
    color: #7b2183;

    .nav-icon {
      margin-right: 10px;

      &.icn-svg {
        transform: scale(1.2);
        fill: #7b2183;
        padding-right: 3px;
        padding-bottom: 2px;
      }

      &.side-bar-dashboard {
        content: url('../../../assets/icon/IcnWebDashboard.svg');
      }

      &.side-bar-homepage {
        content: url('../../../assets/icon/IcnHomepage.svg');
      }

      &.side-bar-person {
        content: url('../../../assets/icon/IcnPerson.svg');
      }

      &.side-bar-editor {
        content: url('../../../assets/icon/IcnEditor.svg');
      }

      &.side-bar-person-voice {
        content: url('../../../assets/icon/IcnPersonVoice.svg');
      }

      &.side-bar-thumbs {
        content: url('../../../assets/icon/IcnThumbs.svg');
      }

      &.side-bar-landing {
        content: url('../../../assets/icon/IcnLanding.svg');
      }

      &.side-bar-footer {
        content: url('../../../assets/icon/IcnFooter.svg');
      }

      &.side-bar-cms-workflow {
        content: url('../../../assets/icon/IcnSideMenuWorkflow.svg');
      }

      &.side-bar-cms-non-workflow {
        content: url('../../../assets/icon/IcnSideMenuNonWorkflow.svg');
      }

      &.side-bar-scoring {
        content: url('../../../assets/icon/IcnSideMenuScoring.svg');
      }

      &.side-bar-course {
        content: url('../../../assets/icon/IcnWebMenuCourse.svg');
      }

      &.side-bar-forum {
        content: url('../../../assets/icon/IcnWebMenuForum.svg');
      }

      &.side-bar-schedule {
        content: url('../../../assets/icon/IcnWebMenuSchedule.svg');
      }

      &.side-bar-group-management {
        content: url('../../../assets/icon/IcnGroup.svg');
      }

      &.side-bar-announcement {
        content: url('../../../assets/icon/IcnWebMenuAnnouncement.svg');
      }

      &.side-bar-beelajar {
        content: url('../../../assets/icon/beelajar.svg');
      }

      &.side-bar-new-beelajar {
        content: url('../../../assets/icon/new_beelajar.svg');
      }

      &.side-bar-bclnd-backend {
        content: url('../../../assets/icon/bclnd_backend.svg');
      }

      &.side-bar-bclnd-frontend {
        content: url('../../../assets/icon/bclnd_frontend.svg');
      }

      &.side-bar-fma {
        content: url('../../../assets/icon/fma.svg');
      }
    }

    &.active {
      background: #7b2183;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      font-weight: 550;

      .icn-svg {
        fill: #7b2183;
      }
    }

    &:hover {
      background: #7b2183;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      color: #fff;

      .icn-svg {
        fill: #7b2183;
      }
    }

    &.nav-link-success {
      background: #4dbd74;
    }

    &.nav-link-danger {
      background: #f86c6b;
    }
  }

  .nav-dropdown.open {
    background: transparent;
  }

  // .nav-dropdown.open ul{
  //   margin-left: 10px;
  // }

  .nav-dropdown.open .nav-link {
    color: #7b2183;
  }

  .nav-dropdown.open .nav-link.active,
  .nav-dropdown.open .nav-link:hover {
    color: #fff;
  }

  .nav-dropdown .nav-item .nav-link {
    margin-left: 7.5px;
  }

  // .nav-dropdown.open .nav-item .nav-link {
  //   margin-left: 25px;
  //   position: relative;
  //   padding-left: 25px;
  //   border-top-left-radius: 20px;
  //   border-bottom-left-radius: 20px;
  // }

  // .nav-item.nav-dropdown.open .nav-item .nav-link .nav-icon {
  //   position: absolute;
  //   left: 10px;

  //   &::before {

  //   }
  // }

  .nav-dropdown-items .nav-link .nav-icon {
    width: 0px;
    margin-left: 21px;
  }

  background: #fff;
}

.dropdown.nav-item {
  .dropdown-header,
  .dropdown-item {
    font-size: 14px;
  }

  .logout.dropdown-item {
    font-size: 14px;
    padding-left: 10px;
    padding: 16px 16px;

    img {
      margin-right: 16px;
    }
  }
}

.transparent-bg.nav-item .nav-link.active {
  background: transparent;

  .nav-icon {
    color: #7b2183 !important;
  }
}

.change-role-wrapper {
  margin-top: $spacing-6;
  margin-left: $margin-4;
  margin-right: $margin-4;
  padding: 8px 12px;
  border-radius: 6px;
  background: $primary-60;
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  .user-fullname {
    @include md-md-bold;
  }

  .user-group,
  .user-points {
    @include sm-sm-bold;
  }

  .user-subgroup,
  .user-campus {
    @include xs-xs-medium;
  }
}

.acad-banner {
  margin: 10px;
  padding: 5px;
  margin-bottom: -30px;
  border-radius: 10px;
  min-height: 125px;
  background-color: white;
}

.C--button.type--1.change-button {
  height: 25px;
  padding-bottom: 0px;
  padding-top: 0px;
  font-size: 12px;
}

.dropdown-avatar {
  display: flex;
  align-items: center;
  padding: 16px;

  .user-picture {
    width: 64px;
    height: 64px;
  }

  .user-info {
    margin-top: 5px;
    margin-left: 16px;

    &-name {
      font-weight: bolder;
      font-size: 14px;
    }

    &-code {
      font-size: 12px;
    }
  }
}

.dropdown-menu {
  transform: translate3d(-145px, 40px, 0px) !important;
  top: 3px !important;
}

.dropdown-menu-item {
  padding-left: 10px;
  padding: 8px 16px;
  border-bottom: 0px;

  img {
    margin-right: 16px;
    height: 20px;
    width: 20px;
  }
}

@media (max-width: 991.98px) {
  .app-body.default-layout {
    margin-top: 90px;
  }

  .app-toogle {
    display: none;
  }
}

.app-body.default-layout {
  overflow-x: visible;
}

// SEMESTA MENU 9
.semestaMenu9 {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 5%;
  vertical-align: middle;
  margin: 10px 10px 10px 15px;
}

.semestasidenav {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  overflow-x: hidden;
  transition: width 0.2s ease-out, height 0.1s ease-out;
  padding-top: 13px;
  transform: scale(1);
  // opacity: 1;
  // transition-property: visibility, opacity;
  // transition-duration: 0s, 0.9s;
  // animation: fade-in 1s;
  // animation:animatezoom 0.18s
}

// @keyframes animatezoom{from{top:-300px;left:-300px;transform:scale(0)} to{top:0;left:0;transform:scale(1)}}

.semestasidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 1em;
  color: #000000;
  display: block;
  transition: 0.3s;
  font-weight: bold;
}

.semestasidenav h3 {
  padding: 8px 8px 8px 20px;
  text-decoration: none;
  font-size: 1.4em;
  color: #000000;
  display: block;
  transition: 0.3s;
  font-weight: bold;
  margin-top: 25px;
}

.semestasidenav a:hover {
  color: rgb(1, 59, 168);
}

.semestasidenav .titleLeft {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 5%;
  text-align: left;
  margin-left: 15px;
}

.semestasidenav .titleRight {
  display: inline-block;
  vertical-align: baseline;
  text-align: left;
}

.semestasidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 0.9em;
  margin-left: 50px;
  color: rgb(56, 211, 238);
  font-weight: bold;
}

.semestahidden {
  // display: none;
  width: 0%;
  transition: 0.1s ease-out;
  overflow-x: hidden;
  opacity: 0;
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 1s, 0s;
  transition-delay: 0s, 1s;
  transform: scale(0);
  // visibility: hidden;
}

#semestaSideOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9997;
  display: none;
}

.menu9-sidebar {
  display: none;
  cursor: pointer;
  margin: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 0px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}

.menu9-sidebar > .semestaMenu9 {
  float: left;
  width: 1.4em;
}

.semesta-nav-open #semestaSideOverlay {
  display: block;
}

.lms-header-title {
  margin: 10px 30px;
  height: 60px;
  padding-left: 10px;
  padding-top: 30px;
  border-left: 1px solid #000;
  position: relative;
}

.lms-header-title img {
  width: 100%;
  height: 20px;
}

.binusmaya-sidebar {
  margin-bottom: 5px;
  height: 18px;
}

.icon-role {
  margin-right: 5px;
  margin-bottom: 2px;
}

.app-header .navbar-toggler {
  min-width: 40px !important;
}

.navbar-toggler-icon {
  width: 2.5em !important;
  margin-left: 10px;
  color: #000;
}

.dragdrop-enabler {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.done-drag {
  color: #f18700;
  background-image: none;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
  height: 25px;
  width: 52px;
}

.drag-hamburg {
  font-size: 35px;
  color: white;
  height: 25px;
  margin-top: -15px;
  text-align: right;
}

.d-lg-none {
  min-width: 40px;
}

.menu9-header {
  margin-top: 8px;
}

@media (max-width: 992px) {
  .menu9-header {
    display: none;
  }

  .menu9-sidebar {
    display: flex;
    align-items: center;

    .semestaMenu9 {
      margin: -5px 10px 0 10px;
    }

    .binusmaya-sidebar {
      margin: 0px 0 0 0;
    }
  }

  .navbar-brand {
    display: none !important;
  }
}

@media (max-width: 440px) {
  .lms-header-title {
    font-size: 20px;
    margin-left: 10px;
  }
}

.menu-onsite-black {
  color: black !important;
}

.btn-outline-warning.btn-lg {
  color: #f08700;
  border-color: #f08700;
}

.btn-outline-warning.btn-lg:hover {
  background: #f08700;
  color: #fff;
}

.btn-lg {
  border-radius: 10px;
  padding: 0.38rem 1rem !important;
}

.nav-item-notification {
  .text-counter {
    position: absolute;
    left: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d73930;
    color: white;
    font-size: 10px;
    width: 26px !important;
    height: 16px !important;
    border-radius: 20px;
    margin-left: 0.5rem;
    user-select: none;
  }

  .icon {
    i {
      background: #00000012;
      padding: 5px;
      border-radius: 50%;
      color: #00000081 !important;
    }
  }
}

.menu-notification {
  width: 400px;
  left: -150px !important;

  .notification-header {
    padding: 10px;
    border-bottom: solid #cecece 1px;

    .title {
      margin-bottom: 0px !important;
    }
  }

  .notification-body {
    padding: 10px;
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;

    .notification-item {
      padding: 10px;
      border: 1px #cecece solid;
      border-radius: 10px;
      margin-bottom: 10px;

      .notification-item-title {
        line-height: 2 !important;
        margin-bottom: 5px !important;
        border-bottom: solid #cecece 1px;
      }
    }

    .notification-item-unread {
      padding: 10px;
      border: 1px #cecece solid;
      border-radius: 10px;
      margin-bottom: 10px;
      background-color: #7b218321;

      .notification-item-title {
        line-height: 2 !important;
        margin-bottom: 5px !important;
        border-bottom: solid #cecece 1px;
      }
    }
  }
}

.full-parent-width {
  width: 100%;
  word-wrap: break-word;
}

.app-toogle-home {
  font-size: 35px;
  margin: 15px;
}

.sidebar-app {
  display: flex;
  height: 100vh;
  margin-left: 0;
  flex-direction: column;
  padding: 0;
  color: #fff;
  background: #fff;
  border-right: 1px solid #c8ced3;

  .nav-item {
    width: 100%;
  }

  .nav-link {
    color: #7b2183;
    display: flex;
    font-weight: 550;
    line-height: 22px;
    padding: 8px 1.7rem 8px 1.3rem !important;

    &:hover {
      background: #7b2183;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      color: #fff;

      .icn-svg {
        fill: #7b2183;
      }
    }
  }
}

.sidebar-app {
  .nav-link {
    .nav-icon {
      width: 30px;
      margin-right: 10px;

      &.side-bar-beelajar {
        content: url('../../../assets/icon/beelajar.svg');
      }

      &.side-bar-new-beelajar {
        content: url('../../../assets/icon/new_beelajar.svg');
      }

      &.side-bar-bclnd-backend {
        content: url('../../../assets/icon/bclnd_backend.svg');
      }

      &.side-bar-bclnd-frontend {
        content: url('../../../assets/icon/bclnd_frontend.svg');
      }

      &.side-bar-fma {
        content: url('../../../assets/icon/fma.svg');
      }
    }
  }
}

.app-footer {
  padding: unset !important;
}

.footer {
  background-color: #474747;
  padding: 50px 0;
  color: #fff;
  width: 100%;
}

.footer .column {
  padding: 30px;
  // border-right: 1px solid #000;
}

.footer .column:last-child {
  border-right: none;
}

.footer .title {
  font-weight: 600;
}

@media (max-width: 767.98px) {
  .footer {
    padding: 25px 0;
  }

  .footer .column {
    padding: 15px 25px;
    border-right: unset;
  }
}

.tooltip {
  z-index: 10000;
}

.b-container {
  display: grid;
  grid-template-columns: 280px 1fr;
  height: 100vh;
  position: relative;

  .b-header {
    z-index: 100;

    &__desktop {
      background: $true-white;
      border-bottom: 1px solid $border;
      width: -webkit-calc(100% - 280px);
      width: calc(100% - 280px);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: fixed;
      padding: 10px 24px;
      height: 60px;
      gap: $spacing-2;

      @media (max-width: 768px) {
        display: none;
        width: 100%;
      }
    }

    &__mobile {
      background: $true-white;
      border-bottom: 1px solid $border;
      display: none;
      flex: 1;
      position: fixed;
      align-items: center;
      justify-content: space-between;
      padding: $spacing-3 $spacing-4;
      width: 100%;

      img {
        flex: 1;
      }

      .b-icon-menu {
        border-radius: $spacing-1;
        cursor: pointer;
      }

      @media (max-width: 768px) {
        display: flex;
      }
    }
  }

  .b-sidebar {
    background: $true-white;
    grid-column: 1;
    height: 100%;
    width: 280px;
    position: fixed;
    padding: $spacing-8 0;
    border-right: 1px solid $border;
    display: flex;
    flex-direction: column;
    gap: $spacing-6;
    z-index: 200;

    div:last-of-type {
      /* Your styles here */
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $primary-60;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .b-main-content {
    grid-column: 2;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
    background-color: $background-admin;

    main {
      padding-top: 84px;
      padding-left: $spacing-6;
      padding-right: $spacing-6;
      padding-bottom: $spacing-6;
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;

      div.footer {
        margin-left: -24px;
        margin-right: -24px;
        margin-bottom: -24px;
        margin-top: 24px;
        width: auto;
      }
    }

    @media (max-width: 768px) {
      grid-column: 1;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}
