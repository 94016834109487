@import './bcolors.scss';
@import './custom-mantine.scss';
@import './../mixins';

p.m-0,
h1.m-0,
h2.m-0,
h3.m-0,
h4.m-0,
h5.m-0,
h6.m-0 {
  margin: 0;
}

.text-small-status {
  @include small-status;
}

.text-heading-one {
  @include heading-heading-one;
}

.text-heading-two {
  @include heading-heading-two;
}

.text-heading-three {
  @include heading-heading-three;
}

.text-xl-bold {
  @include xl-xl-bold;
}

.text-xl-semibold {
  @include xl-xl-semibold;
}

.text-xl-medium {
  @include xl-xl-medium;
}

.text-xl-regular {
  @include xl-xl-regular;
}

.text-lg-bold {
  @include lg-lg-bold;
}

.text-lg-semibold {
  @include lg-lg-semibold;
}

.text-lg-medium {
  @include lg-lg-medium;
}

.text-lg-regular {
  @include lg-lg-regular;
}

.text-md-bold {
  @include md-md-bold;
}

.text-md-semibold {
  @include md-md-semibold;
}

.text-md-medium {
  @include md-md-medium;
}

.text-md-regular {
  @include md-md-regular;
}

.text-sm-bold {
  @include sm-sm-bold;
}

.text-sm-semibold {
  @include sm-sm-semibold;
}

.text-sm-medium {
  @include sm-sm-medium;
}

.text-sm-regular {
  @include sm-sm-regular;
}

.text-xs-bold {
  @include xs-xs-bold;
}

.text-xs-semibold {
  @include xs-xs-semibold;
}

.text-xs-medium {
  @include xs-xs-medium;
}

.text-xs-regular {
  @include xs-xs-regular;
}
