@import './../../scss/variables';
@import './../../scss/mixins';

.rater-form-detail {
  &__title-rater {
    @include sm-sm-medium;
    color: $tertiary;

    strong {
      @include sm-sm-bold;
      color: $secondary;
    }
  }

  &__title {
    @include xs-xs-medium;
    color: $tertiary;
  }

  &__description {
    @include sm-sm-semibold;
    color: $secondary;
  }
}

.wrapper-grand-total {
  background: $background;
  border-radius: $spacing-4;
}
