@import '../variables';

// font color pallete
.text-secondary {
  color: $secondary !important;
}

.text-tertiary {
  color: $tertiary !important;
}

.text-placeholder {
  color: $placeholder !important;
}

.text-border {
  color: $border !important;
}

.text-background {
  color: $background !important;
}

.text-true-white {
  color: $true-white !important;
}

.text-light-primary {
  color: $light-primary !important;
}

.text-primary-10 {
  color: $primary-10 !important;
}

.text-primary-20 {
  color: $primary-20 !important;
}

.text-primary-30 {
  color: $primary-30 !important;
}

.text-primary-40 {
  color: $primary-40 !important;
}

.text-primary-50 {
  color: $primary-50 !important;
}

.text-primary-60 {
  color: $primary-60 !important;
}

.text-primary-70 {
  color: $primary-70 !important;
}

.text-primary-80 {
  color: $primary-80 !important;
}

.text-primary-90 {
  color: $primary-90 !important;
}

.text-primary-100 {
  color: $primary-100 !important;
}
.text-red-10 {
  color: $red-10 !important;
}

.text-red-20 {
  color: $red-20 !important;
}

.text-red-30 {
  color: $red-30 !important;
}

.text-red-40 {
  color: $red-40 !important;
}

.text-red-50 {
  color: $red-50 !important;
}

.text-red-60 {
  color: $red-60 !important;
}

.text-red-70 {
  color: $red-70 !important;
}

.text-red-80 {
  color: $red-80 !important;
}

.text-red-90 {
  color: $red-90 !important;
}

.text-red-100 {
  color: $red-100 !important;
}

.text-green-10 {
  color: $green-10 !important;
}

.text-green-20 {
  color: $green-20 !important;
}

.text-green-30 {
  color: $green-30 !important;
}

.text-green-40 {
  color: $green-40 !important;
}

.text-green-50 {
  color: $green-50 !important;
}

.text-green-60 {
  color: $green-60 !important;
}

.text-green-70 {
  color: $green-70 !important;
}

.text-green-80 {
  color: $green-80 !important;
}

.text-green-90 {
  color: $green-90 !important;
}

.text-green-100 {
  color: $green-100 !important;
}
.text-grey-10 {
  color: $grey-10 !important;
}

.text-grey-20 {
  color: $grey-20 !important;
}

.text-grey-30 {
  color: $grey-30 !important;
}

.text-grey-40 {
  color: $grey-40 !important;
}

.text-grey-50 {
  color: $grey-50 !important;
}

.text-grey-60 {
  color: $grey-60 !important;
}

.text-grey-70 {
  color: $grey-70 !important;
}

.text-grey-80 {
  color: $grey-80 !important;
}

.text-orange-10 {
  color: $orange-10 !important;
}

.text-orange-20 {
  color: $orange-20 !important;
}

.text-orange-30 {
  color: $orange-30 !important;
}

.text-orange-40 {
  color: $orange-40 !important;
}

.text-orange-50 {
  color: $orange-50 !important;
}

.text-orange-60 {
  color: $orange-60 !important;
}

.text-orange-70 {
  color: $orange-70 !important;
}

.text-orange-80 {
  color: $orange-80 !important;
}

.text-orange-90 {
  color: $orange-90 !important;
}

.text-orange-100 {
  color: $orange-100 !important;
}

.text-yellow-10 {
  color: $yellow-10 !important;
}

.text-yellow-20 {
  color: $yellow-20 !important;
}

.text-yellow-30 {
  color: $yellow-30 !important;
}

.text-yellow-40 {
  color: $yellow-40 !important;
}

.text-yellow-50 {
  color: $yellow-50 !important;
}

.text-yellow-60 {
  color: $yellow-60 !important;
}

.text-yellow-70 {
  color: $yellow-70 !important;
}

.text-yellow-80 {
  color: $yellow-80 !important;
}

.text-yellow-90 {
  color: $yellow-90 !important;
}

.text-yellow-100 {
  color: $yellow-100 !important;
}

.text-blue-10 {
  color: $blue-10 !important;
}

.text-blue-20 {
  color: $blue-20 !important;
}

.text-blue-30 {
  color: $blue-30 !important;
}

.text-blue-40 {
  color: $blue-40 !important;
}

.text-blue-50 {
  color: $blue-50 !important;
}

.text-blue-60 {
  color: $blue-60 !important;
}

.text-blue-70 {
  color: $blue-70 !important;
}

.text-blue-80 {
  color: $blue-80 !important;
}

.text-blue-90 {
  color: $blue-90 !important;
}

.text-blue-100 {
  color: $blue-100 !important;
}
