@import '../../../../../scss/variables';
@import '../../../../../scss/mixins';

.wrapper {
  display: flex;
}

.container {
  display: flex;
  gap: $spacing-3;
  padding: 0 $spacing-4;
  align-items: center;

  .app-grid-logo,
  .brand-logo {
    cursor: pointer;
  }

  .app-grid-logo {
    padding: $spacing-1;
    // border-radius: $spacing-1;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: $grey-10;
    }
  }
}

.container-profile-user {
  background-color: $primary-60;
  color: $true-white;
  padding: $spacing-2 $spacing-3;
  border-radius: $spacing-2;
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  margin-right: $spacing-4;
  margin-left: $spacing-4;

  .wrapper-job-title {
    display: flex;
    flex-direction: column;

    h5 {
      @include sm-sm-bold;
    }

    p {
      @include xs-xs-bold;
    }
  }

  h3 {
    @include md-md-bold;
  }

  h6 {
    @include sm-sm-bold;
  }
}

.other-sidebar-app {
  background-color: $true-white;
  border-right: 1px solid $border;
  padding: 0 $spacing-4;
  padding-top: $spacing-8;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 100%;
  width: 280px;
  z-index: 99999;
  transition: all 0.2s ease-in-out;
  box-shadow: 5px 0 50px rgba(0, 0, 0, 0.5);
  margin-top: -$spacing-8;

  .application-container {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
    border: none;

    h2 {
      @include md-md-semibold;
      padding-left: $spacing-2;
      padding-right: $spacing-2;
    }

    a {
      padding: $spacing-2;
      border-radius: $spacing-1;
      transition: all 0.3s ease-in-out;
      width: fit-content;

      &:hover {
        background-color: $grey-10;
      }
    }

    &__nav {
      list-style: none;
      padding: 0;

      li.nav-item > a {
        width: 100%;
        color: $primary-60;
        display: flex;
      }

      .nav-icon {
        margin-right: 10px;
        width: 24px;
        height: 24px;

        &.icn-svg {
          transform: scale(1.2);
          fill: $primary-60;
          padding-right: 3px;
          padding-bottom: 2px;
        }

        &.side-bar-dashboard {
          content: url('../../../../../assets/icon/IcnWebDashboard.svg');
        }

        &.side-bar-homepage {
          content: url('../../../../../assets/icon/IcnHomepage.svg');
        }

        &.side-bar-person {
          content: url('../../../../../assets/icon/IcnPerson.svg');
        }

        &.side-bar-editor {
          content: url('../../../../../assets/icon/IcnEditor.svg');
        }

        &.side-bar-person-voice {
          content: url('../../../../../assets/icon/IcnPersonVoice.svg');
        }

        &.side-bar-thumbs {
          content: url('../../../../../assets/icon/IcnThumbs.svg');
        }

        &.side-bar-landing {
          content: url('../../../../../assets/icon/IcnLanding.svg');
        }

        &.side-bar-footer {
          content: url('../../../../../assets/icon/IcnFooter.svg');
        }

        &.side-bar-cms-workflow {
          content: url('../../../../../assets/icon/IcnSideMenuWorkflow.svg');
        }

        &.side-bar-cms-non-workflow {
          content: url('../../../../../assets/icon/IcnSideMenuNonWorkflow.svg');
        }

        &.side-bar-scoring {
          content: url('../../../../../assets/icon/IcnSideMenuScoring.svg');
        }

        &.side-bar-course {
          content: url('../../../../../assets/icon/IcnWebMenuCourse.svg');
        }

        &.side-bar-forum {
          content: url('../../../../../assets/icon/IcnWebMenuForum.svg');
        }

        &.side-bar-schedule {
          content: url('../../../../../assets/icon/IcnWebMenuSchedule.svg');
        }

        &.side-bar-group-management {
          content: url('../../../../../assets/icon/IcnGroup.svg');
        }

        &.side-bar-announcement {
          content: url('../../../../../assets/icon/IcnWebMenuAnnouncement.svg');
        }

        &.side-bar-beelajar {
          content: url('../../../../../assets/icon/beelajar.svg');
        }

        &.side-bar-new-beelajar {
          content: url('../../../../../assets/icon/new_beelajar.svg');
        }

        &.side-bar-bclnd-backend {
          content: url('../../../../../assets/icon/bclnd_backend.svg');
        }

        &.side-bar-bclnd-frontend {
          content: url('../../../../../assets/icon/bclnd_frontend.svg');
        }

        &.side-bar-fma {
          content: url('../../../../../assets/icon/fma.svg');
        }
      }
    }
  }

  &.open {
    opacity: 1;
    visibility: visible;
  }
}
