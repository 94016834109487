@import './../../../scss/variables';
@import './../../../scss/mixins';

.collapse-rater-input-container {
  &__header {
    &.open {
      border-bottom: 1px solid $border;
      padding-bottom: $spacing-4;
    }

    &_title {
      @include md-md-bold;
      color: $secondary;
    }

    &_description {
      @include sm-sm-medium;
      color: $tertiary;
    }

    &_collapse-button {
      cursor: pointer;
      padding: $spacing-1;
      border-radius: $spacing-2;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: $grey-10;
      }
    }

    &_expectation {
      border-radius: 10px;
      background-color: $background;
      padding: $spacing-3 $spacing-4;

      p {
        @include sm-sm-medium;
      }
    }
  }
}

.container-radio-button {
  .mantine-RadioGroup-description {
    color: $primary-60;
  }
}
