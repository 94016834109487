@import '../../../scss/variables';
@import '../../../scss/mixins';

.container-detail-method {
  align-self: stretch;
  border-bottom: 1px solid $border;
  border-top: 1px solid $border;
  border-left: 1px solid $border;
  background-color: $true-white;
  display: flex;
  flex-direction: column;

  .header-table-method {
    background-color: $background;
    display: flex;
    width: 100%;
    gap: 0px;

    div {
      border-right: 1px solid $border;
    }

    div::last-child {
      border-right: none;
    }

    &__column {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 7px;
      width: 80px;
      border-bottom: 1px solid $border;

      p {
        @include xs-xs-medium;
        color: $secondary;
        text-align: center;
        word-break: normal;
        -ms-word-break: normal;
      }
    }

    &__description_container {
      display: flex;
      flex-grow: 1;
      flex-basis: 0%;
      flex-direction: column;

      span {
        border-bottom: 1px solid $border;
      }

      span::last-child {
        border-bottom: none;
      }
    }

    &__description {
      color: $secondary;
      white-space: nowrap;
      justify-content: center;
      display: flex;
      align-items: center;
      padding: 0 60px;

      h6 {
        @include xs-xs-medium;
      }

      .title {
        @include md-md-bold;
      }
    }

    &__description_title {
      color: $secondary;
      white-space: nowrap;
      justify-content: center;
      display: flex;
      align-items: center;
      padding: 0 60px;
      flex: 1;

      h6 {
        @include xs-xs-medium;
      }

      .title {
        @include md-md-bold;
      }
    }
  }

  .footer-table-method {
    display: flex;
    width: 100%;
    gap: 0px;

    div {
      border-bottom: 1px solid $border;
      border-top: 1px solid $border;
      border-right: 1px solid $border;
    }

    div::last-child {
      border-right: none;
    }

    &__grand-total {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-start;
      padding: 8px 12px;

      p {
        @include xs-xs-bold;
      }
    }

    &__score {
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 7px;

      p {
        @include xs-xs-medium;
      }
    }
  }
}

.row-table-method {
  display: flex;
  width: 100%;
  gap: 0px;

  div {
    border-right: 1px solid $border;
  }

  div::last-child {
    border-right: none;
  }

  &__numbering {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 80px;
    padding: 20px 7px;

    p {
      @include xs-xs-medium;
    }
  }

  &__column {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 80px;
    padding: 20px 7px;

    p {
      @include xs-xs-medium;
    }

    &__description {
      align-items: center;
      justify-content: flex-start;
      display: flex;
      width: 80px;
      padding: 8px;
      flex: 1;

      p {
        @include xs-xs-medium;
      }
    }
  }
}

.summary-table-method {
  padding: $spacing-4;
  border-right: 1px solid $border;
  gap: 10px;
  display: flex;
  flex-direction: column;

  &__title {
    @include sm-sm-bold;
    color: $secondary;
  }

  &__description {
    @include xs-xs-semibold;
    color: $tertiary;

    span {
      @include xs-xs-bold;
      color: $secondary;
    }
  }

  &__button_information {
    cursor: pointer;
  }
}

.b-border {
  border-bottom: 1px solid $border;
}
