// Variable overrides
@charset "utf-8";

@import '~@coreui/coreui/scss/variables';

@import './themes/variables.scss';

$color-gray-be: #bebebe;
$gray-D8D8D8: #d8d8d8;
$gray-CCCCCC: #cccccc;
$orange-theme: #f29115;
$binus-blue: #5f1965;
$binus-dark-blue: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
  #5f1965;
$incomplete: #ff4e45;
$incomplete-background: #ffdcda;

$gap-small: 10px;
$gap-normal: 15px;
$gap-medium: 20px;
$gap-large: 30px;

$deliv-mode-virtual-class: #880f88;
$deliv-mode-onsite: #666666;
$deliv-mode-online: #028ed5;
$deliv-mode-other: #d73930;

$dark-orange-thene: #d8800e;
// /**
//  * Define global configuration variable.
//  */
// $primary-color: #0366d6;
// $text-color: #333;
// $border-color: rgba(0,0,0,.08);

// // screen resolution
// $screen-extra-small: 480px;
// $screen-small: 768px;
// $screen-medium: 1024px;
// $screen-large: 1200px;
/*paint style*/

// color system

$secondary: rgba(51, 51, 51, 1);
$dark-theme-background: rgb(30 27 30);
$dark-theme-text: rgb(249 249 249);
$tertiary: rgba(102, 102, 102, 1);
$placeholder: rgba(153, 153, 153, 1);
$border: rgba(204, 204, 204, 1);
$background: rgba(242, 242, 242, 1);
$true-white: rgba(255, 255, 255, 1);
$light-primary: rgba(229, 199, 230, 1);
$primary-10: rgba(247, 222, 248, 1);
$primary-20: rgba(176, 123, 180, 1);
$primary-30: rgba(163, 100, 170, 1);
$primary-40: rgba(149, 77, 156, 1);
$primary-50: rgba(136, 56, 142, 1);
$primary-60: rgba(123, 33, 131, 1);
$primary-70: rgba(99, 27, 104, 1);
$primary-80: rgba(86, 23, 93, 1);
$primary-90: rgba(74, 20, 79, 1);
$primary-100: rgba(61, 16, 65, 1);
$red-10: rgba(253, 219, 217, 1);
$red-20: rgba(229, 123, 115, 1);
$red-30: rgba(225, 101, 93, 1);
$red-40: rgba(219, 77, 72, 1);
$red-50: rgba(215, 56, 48, 1);
$red-60: rgba(209, 33, 26, 1);
$red-70: rgba(169, 26, 21, 1);
$red-80: rgba(147, 24, 17, 1);
$red-90: rgba(127, 20, 14, 1);
$red-100: rgba(105, 16, 12, 1);
$green-10: rgba(235, 255, 218, 1);
$green-20: rgba(173, 213, 143, 1);
$green-30: rgba(160, 204, 123, 1);
$green-40: rgba(147, 196, 105, 1);
$green-50: rgba(134, 191, 87, 1);
$green-60: rgba(117, 184, 67, 1);
$green-70: rgba(96, 146, 54, 1);
$green-80: rgba(83, 128, 46, 1);
$green-90: rgba(71, 111, 40, 1);
$green-100: rgba(60, 91, 33, 1);
$grey-10: rgba(232, 232, 232, 1);
$grey-20: rgba(217, 217, 217, 1);
$grey-30: rgba(214, 214, 214, 1);
$grey-40: rgba(204, 204, 204, 1);
$grey-50: rgba(184, 184, 184, 1);
$grey-60: rgba(179, 179, 179, 1);
$grey-70: rgba(153, 153, 153, 1);
$grey-80: rgba(128, 128, 128, 1);
$orange-10: rgba(255, 238, 218, 1);
$orange-20: rgba(245, 185, 102, 1);
$orange-30: rgba(246, 171, 78, 1);
$orange-40: rgba(243, 158, 51, 1);
$orange-50: rgba(242, 148, 27, 1);
$orange-60: rgba(241, 135, 0, 1);
$orange-70: rgba(192, 108, 2, 1);
$orange-80: rgba(169, 93, 2, 1);
$orange-90: rgba(143, 82, 3, 1);
$orange-100: rgba(120, 66, 1, 1);
$yellow-10: rgba(255, 249, 227, 1);
$yellow-20: rgba(251, 223, 115, 1);
$yellow-30: rgba(249, 218, 94, 1);
$yellow-40: rgba(250, 214, 71, 1);
$yellow-50: rgba(249, 206, 47, 1);
$yellow-60: rgba(247, 201, 27, 1);
$yellow-70: rgba(199, 162, 20, 1);
$yellow-80: rgba(172, 141, 15, 1);
$yellow-90: rgba(148, 121, 12, 1);
$yellow-100: rgba(122, 102, 13, 1);
$blue-10: rgba(216, 242, 255, 1);
$blue-20: rgba(104, 187, 230, 1);
$blue-30: rgba(79, 176, 226, 1);
$blue-40: rgba(52, 163, 220, 1);
$blue-50: rgba(27, 154, 215, 1);
$blue-60: rgba(0, 142, 214, 1);
$blue-70: rgba(0, 114, 169, 1);
$blue-80: rgba(1, 99, 148, 1);
$blue-90: rgba(0, 84, 130, 1);
$blue-100: rgba(1, 71, 105, 1);
$background-search: rgba(253, 251, 255, 1);
$background-initial-input: rgba(250, 250, 250, 1);
$background-admin: rgba(248, 247, 248, 1);
$background-testimony: #fbfbfb;

// Spacing
$spacing-0: 0px; // 0px
$spacing-1: 0.25rem; // 4px
$spacing-2: 0.5rem; // 8px
$spacing-3: 0.75rem; // 12px
$spacing-4: 1rem; // 16px
$spacing-5: 1.25rem; // 20px
$spacing-6: 1.5rem; // 24px
$spacing-8: 2rem; // 32px
$spacing-10: 2.5rem; // 40px
$spacing-12: 3rem; // 48px
$spacing-16: 4rem; // 64px
$spacing-20: 5rem; // 80px
$spacing-24: 6rem; // 96px
$spacing-32: 8rem; // 128px
$spacing-40: 10rem; // 160px
$spacing-48: 12rem; // 192px
$spacing-56: 14rem; // 224px
$spacing-64: 16rem; // 256px

// Conversion from rem to px:
// 1rem = 16px

// Margin
$margin-0: 0px; // 0px
$margin-1: 0.25rem; // 4px
$margin-2: 0.5rem; // 8px
$margin-3: 0.75rem; // 12px
$margin-4: 1rem; // 16px
$margin-5: 1.25rem; // 20px
$margin-6: 1.5rem; // 24px
$margin-8: 2rem; // 32px
$margin-10: 2.5rem; // 40px
$margin-12: 3rem; // 48px
$margin-16: 4rem; // 64px
$margin-20: 5rem; // 80px
$margin-24: 6rem; // 96px
$margin-32: 8rem; // 128px
$margin-40: 10rem; // 160px
$margin-48: 12rem; // 192px
$margin-56: 14rem; // 224px
$margin-64: 16rem; // 256px
/*text style*/

@mixin small-status {
  font-size: 10px;
  font-family: Open Sans;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 150%;
}
@mixin heading-heading-one {
  font-size: 64px;
  font-family: Open Sans;
  font-weight: 700;
  letter-spacing: 0.025em;
  line-height: 159.99999046325684%;
}
@mixin heading-heading-two {
  font-size: 52px;
  font-family: Open Sans;
  font-weight: 700;
  letter-spacing: 0.025em;
  line-height: 159.99999046325684%;
}
@mixin heading-heading-three {
  font-size: 48px;
  font-family: Open Sans;
  font-weight: 700;
  letter-spacing: 0.025em;
  line-height: 159.99999046325684%;
}
@mixin xl-xl-bold {
  font-size: 32px;
  font-family: Open Sans;
  font-weight: 800;
  letter-spacing: 0em;
  line-height: 129.99999523162842%;
}
@mixin xl-xl-semibold {
  font-size: 32px;
  font-family: Open Sans;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 129.99999523162842%;
}
@mixin xl-xl-medium {
  font-size: 32px;
  font-family: Open Sans;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 129.99999523162842%;
}
@mixin xl-xl-regular {
  font-size: 32px;
  font-family: Open Sans;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 129.99999523162842%;
}
@mixin lg-lg-bold {
  font-size: 24px;
  font-family: Open Sans;
  font-weight: 800;
  letter-spacing: 0em;
}
@mixin lg-lg-semibold {
  font-size: 24px;
  font-family: Open Sans;
  font-weight: 600;
  letter-spacing: 0em;
}
@mixin lg-lg-medium {
  font-size: 24px;
  font-family: Open Sans;
  font-weight: 400;
  letter-spacing: 0em;
}
@mixin lg-lg-regular {
  font-size: 24px;
  font-family: Open Sans;
  font-weight: 400;
  letter-spacing: 0em;
}
@mixin md-md-bold {
  font-size: 18px;
  font-family: Open Sans;
  font-weight: 800;
  letter-spacing: 0em;
}
@mixin md-md-semibold {
  font-size: 18px;
  font-family: Open Sans;
  font-weight: 600;
  letter-spacing: 0em;
}
@mixin md-md-medium {
  font-size: 18px;
  font-family: Open Sans;
  font-weight: 400;
  letter-spacing: 0em;
}
@mixin md-md-regular {
  font-size: 18px;
  font-family: Open Sans;
  font-weight: 400;
  letter-spacing: 0em;
}
@mixin sm-sm-bold {
  font-size: 14px;
  font-family: Open Sans;
  font-weight: 800;
  letter-spacing: 0em;
  line-height: 150%;
}
@mixin sm-sm-semibold {
  font-size: 14px;
  font-family: Open Sans;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 150%;
}
@mixin sm-sm-medium {
  font-size: 14px;
  font-family: Open Sans;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 150%;
}
@mixin sm-sm-regular {
  font-size: 14px;
  font-family: Open Sans;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 150%;
}
@mixin xs-xs-bold {
  font-size: 12px;
  font-family: Open Sans;
  font-weight: 800;
  letter-spacing: 0em;
  line-height: 150%;
}
@mixin xs-xs-semibold {
  font-size: 12px;
  font-family: Open Sans;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 150%;
}
@mixin xs-xs-medium {
  font-size: 12px;
  font-family: Open Sans;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 150%;
}
@mixin xs-xs-regular {
  font-size: 12px;
  font-family: Open Sans;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 150%;
}
