@import '../../scss/vendors/bcolors.scss';
@import '../../scss/mixins';

.indicator {
  width: 10px !important;
  height: 10px !important;
  transition: width 500ms ease;
  background-color: $primary-30;

  &[data-active] {
    background-color: $primary-60;
    width: 40px !important;
  }
}

.indicators {
  gap: 5px;
  top: 102%;
}

.control {
  background-color: $orange-60;
  color: $true-white;
  border: $orange-60;
  width: 35px;
  height: 35px;
}

.root {
  &:hover {
    .controls {
      opacity: 1;
    }
  }
}

.viewport {
  border-radius: 10px;
}

.control-container {
  color: $secondary;
  padding: $spacing-3;
  border-radius: 50%;
  background-color: $true-white;
  opacity: 0.7;
  transition: all 150ms ease;
  height: 52px;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }
}
