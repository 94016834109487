@import '../../../scss//variables';
@import '../../../scss//mixins';

.container-navigation {
  background-color: $true-white;
  display: flex;
  gap: $spacing-2;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 10px;
  border-radius: $spacing-2;
  border: 1px solid $border;
  width: 350px;

  .input-wrapper {
    flex: 1;
  }

  input {
    border: none;
    outline: none;
    width: 100%;
    font-size: $spacing-3;
    font-weight: 400;
    color: $secondary;
    background-color: transparent;

    &::placeholder {
      color: $tertiary;
    }
  }

  div.loading {
    width: 15px;
    height: 15px;
    border: 2px solid $secondary;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 1s infinite linear;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  @media (max-width: 1024px) {
    width: max-content;
  }
}
