@import '../../../scss/variables';
@import '../../../scss/mixins';

.draggable-container {
  display: flex;
  flex-direction: column;

  &.dragging-over {
    background-color: $grey-10;
  }

  &__item {
    border-bottom: 1px solid $grey-60;
    background-color: $true-white;
    padding: 10px;
    transition: all 0.2s ease-in-out;

    &:first-child {
      border-top: 1px solid $grey-60;
      border-bottom: 1px solid $grey-60;
    }

    &:last-child {
      border-bottom: 1px solid $grey-60;
    }

    &:hover {
      background-color: $grey-10;
    }

    &.dragging-over {
      background-color: $primary-10;
    }
  }
}
